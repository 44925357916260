export default {
  list: {
    headers: {
      id: "ID",
      name: "Nazwa",
      slug: "Slug",
      firm: "Firma",
      email: "Email",
      live_url: "Live url",
      dp_group_name: "Nazwa grupy",
      started_at: "Data odblokowania ",
      title_private: "Tytuł",
      question: "Pytanie",
      homework_id: "Homework ID",
      type: "Typ",
      description: "Opis",
      dp_mural_name: "Nazwa tablicy",
      group_names: "Grupa",
      question_private: "Question private",
      stage: "Krok",
    },
    sections: "sekcje",
    groups: "grupy",
    groupusers: "uczestnicy",
    modules: "moduły",
    homeworks: "zadania domowe",
    assessment: "assasmenty",
    games: "gry RPG",
    preworks: "preworki",
    livewidgets: "Spotkania online",
    kickoffs: "Kickoffs",
    precertificates: "precertyfikacja",
    schedules: "daty odblokowania modułów",
    edit: "edytuj",
    templates: "szablony tablic",
    reflections: "refleksje",
    materials: "dodaj materiał",
    materialslist: "lista materiałów",
    assessmentmodule: "dodaj assessment do modułu",
    assessmentlist: "lista przypisanych assessmentów",
  },
  sections: {
    headers: {
      name: "Nazwa",
    },
  },
  form: {
    labels: {
      name: "Nazwa",
      slug: "Slug",
      description: "Opis",
      order: "Kolejność",
      tabs: "Kolejność tabow",
      color: "Kolor",
      programid: "ID programu",
      sectionid: "ID sekcji",
      moduleid: "ID modułu",
      stage: "Krok",
      rpg_url: "Url gry RPG",
      rpg_description: "Opis gry RPG",
      type: "Typ",
      preworkid: "ID ścieżki",
      live_url: "Url spotkania",
      start_date: "Data rozpoczenia",
      end_date: "Data zakończenia",
      start_at: "Data odblokowania",
      duration: "Czas trwania",
      group: "ID grupy",
      max_group_users: "Maksymalna liczba userów w grupie",
      calendar_url: "Link do kalendarza",
      position: "Stanowisko",
      unlock_before_days: "Ile dni przed odblokować",
      active: "Aktywna",
      status: "Status",
      widget_status: "Widget_status",
      is_expired: "Is_expired",
      is_conference: "Is_conference",
      question: " Pytanie",
      menu_name_pl: "Menu PL",
      menu_name_en: "Menu eng",
      disabled_homeworks: "Blokowanie pracy domowej w oczekiwaniu na live",
      disabled_tools: "Blokowanie narzędzi w oczekiwaniu na live",
      show_welcome_baner: "Pokaż baner powitalny",
      show_exams: "Pokaż egzaminy",
      navigation_expandable: "Zwijana nawigacja",
      muralid: "ID tablicy",
      title_private: "Tytuł prywatny",
      title_public: "Tytuł publiczny",
      subtitle: "Subtytuł",
      lead: "Lead",
      description_private: "Opis prywatny",
      description_public: "Opis publiczny",
      thanks_title: "Tytuł podziękowań",
      thanks_description: "Opis podziękowań",
      presentation_type: "Typ prezentacji",
      is_presented: "Is presented",
      is_presented_by_categories: "Is presented by categories",
      is_presented_by_questions: "Is presented by questions",
      is_interpreted: "Is interpreted",
      is_identity_requirement: "Is identity requirement",
      identity_requirement_data: "Identity requirement data",
      has_uuids: "Has uuids",
      is_categorized: "Is categorized",
      is_random_questions: "Is random questions",
      is_active: "Aktywna",
      is_hidden: "Is hidden",
      is_archived: "Is archived",
      access_level: "access_level",
      tools_title: "Tytuł danego assesmentu w widoku Narzędzi",
      title: "Tytuł",
      tools_description: "Opis w widoku Narzędzi",
      label: "Label",
      assessment_subtitle: "Assessment subtitle",
      hex_color: "Kolor",
      joinable_before_days: "Użytkownik może dołączyć wcześniej (dni)",
      active_from: "Aktywny od dnia",
      is_challenge: "Wyzwanie",
      google_calendar_link: "Link do kalendarza Google",
      outlook_calendar_link: "Link do kalendarza Outlook",
      cover: "Okładka",
      cover_description: "okładki",
      font_color: "Zmień font na czarny",
      vertical_cover_web: "Okładka web pionowa",
      horizontal_cover_web: "Okładka web pozioma",
      wide_vertical_cover_web: "Okładka web pionowa szeroka",
      vertical_cover_mobile: "Okładka mobile pionowa",
      long_vertical_cover_web: "Okladka mobile pionowa długa",
      cover_banner_web: "Okładka baner web",
      cover_banner_mobile: "Okładka baner mobile"
    },
    buttons: {
      save: "Zapisz program",
      send: "Dodaj program",
    },
    sections: {
      save: "Zapisz sekcję",
      send: "Dodaj sekcję",
    },
    modules: {
      save: "Zapisz moduł",
      send: "Dodaj moduł",
    },
    groups: {
      save: "Zapisz uźytkownika",
      send: "Dodaj uźytkownika",
    },
    homeworks: {
      send: "Dodaj prace domową",
      save: "Zapisz prace domową",
    },
    assessmentModule: {
      send: "Dodaj",
      save: "Zapisz",
    },
    preworks: {
      send: "Dodaj prework",
    },
    assessment: {
      send: "Dodaj",
      save: "Zapisz",
    },
    group: {
      save: "Zapisz grupę",
      send: "Dodaj grupę",
    },
    widgets: {
      save: "Zapisz spotkanie",
      send: "Dodaj spotkanie",
    },
    precertificates: {
      save: "Zapisz",
      send: "Dodaj",
    },
    reflections: {
      save: "Zapisz refleksę",
      send: "Dodaj refleksję",
    },
    murals: {
      save: "Zapisz szablon talicy",
      send: "Dodaj szablon talicy",
    },
  },
  types: {
    program: "Program",
    path: "CSR",
  },
};
